<template>
  <div class="fobidden">
    <h1 class="mt-3 no-account">このユーザーは存在しません</h1>
    <b-button variant="primary" class="f-w3 my-4" @click="backToHome"
      >HOMEへ戻る</b-button
    >
    <!-- <router-link :to="redirectUrl">
      <img src="../assets/image/403.png" alt="" />
    </router-link> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      auth: "auth/user"
    }),
    redirectUrl() {
      if (this.auth.sex === 1) {
        return "/";
      }
      return "/cast";
    }
  },
  methods: {
    backToHome() {
      if (this.auth.sex === 1) {
        this.$router.push({ name: "Home" });
      } else {
        this.$router.push({ name: "CastHome" });
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../assets/scss/fobidden.scss";

@media screen and (max-width: 896px) {
  .no-account {
    font-size: 20px;
  }
}
</style>
